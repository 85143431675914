import { css } from "styled-components"

// Styling
import { fonts, fontWeights } from "./fonts"
import { mq } from "./breakpoints"

// Text styles
export default {
  pageTitle: css`
    margin: 0 0 56px;
    font-family: ${fonts.primary};
    font-size: 48px;
    font-weight: ${fontWeights.bold};
    line-height: 1.125;

    ${mq.from.M`
      margin: 0 0 60px;
      font-size: 72px;
    `};

    ${mq.from.L`
      font-size: 96px;
    `};
  `,
  title: css`
    margin: 0;
    font-family: ${fonts.primary};
    font-size: 24px;
    font-weight: ${fontWeights.bold};
    line-height: 1.125;

    ${mq.from.M`
      font-size: 36px;
    `};

    ${mq.from.L`
      font-size: 48px;
    `};
  `,
  body: css`
    margin: 0 0 24px;
    font-family: ${fonts.primary};
    font-size: 16px;
    font-weight: ${fontWeights.medium};
    line-height: 1.5;

    ${mq.from.M`
      margin: 0 0 32px;
      font-size: 20px;
    `};

    ${mq.from.L`
      margin: 0 0 40px;
      font-size: 24px;
    `};
  `,
  quote: css`
    margin: 0 0 24px;
    font-family: ${fonts.primary};
    font-size: 24px;
    line-height: 1.33;

    ${mq.from.L`
      margin: 0 0 40px;
      font-size: 36px
  `};
  `,
}
