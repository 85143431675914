import React from "react"

// Styling
import colors from "../../styles/colors"

// Icons
import Arrow from "./Arrow"

const iconMap = {
  arrow: Arrow,
}

export interface IIconProps {
  width?: number
  height?: number
  color?: string
  className?: string
}

type IProps = IIconProps & {
  icon: keyof typeof iconMap
}

const Icon = ({ icon, width, height, color, className }: IProps) => {
  const IconComponent = iconMap[icon]
  return (
    <IconComponent
      width={width}
      height={height}
      color={color}
      className={className}
    />
  )
}

Icon.defaultProps = {
  width: 24,
  height: 24,
  color: colors.white,
}

export default Icon
