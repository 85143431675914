import React from "react"
import styled from "styled-components"

// Images
import happyImage from "../assets/happyhappynewnew.png"
import tagsImage from "../assets/tags.png"
import patternImage from "../assets/logopatterns.png"

// Styling
import colors from "../styles/colors"
import { mq } from "../styles/breakpoints"

// Component
import Icon from "./icons/Icon"

const Header = () => (
  <Container>
    <ImageContainer>
      <HappyImage
        src={happyImage}
        role="img"
        alt="Happy New Decade, with ❤️ from Jungle Minds"
      />
      <TagsImage
        src={tagsImage}
        role="img"
        alt="Happy New Decade, with ❤️ from Jungle Minds"
      />
      <PatternImage
        src={patternImage}
        role="img"
        alt="Happy New Decade, with ❤️ from Jungle Minds"
      />
    </ImageContainer>
    <ScrollHint>
      <Icon icon="arrow" width={56} height={56} color={colors.black} />
    </ScrollHint>
  </Container>
)

const Container = styled.div`
  margin-bottom: 80px;

  ${mq.from.M`
    margin-bottom: 120px;
  `}
`

const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  margin-bottom: 80px;
  background-color: ${colors.black};
  padding: 40px 0 48px;

  ${mq.from.M`
    flex-direction: row;
    height: 640px;
    overflow-x: scroll;
    padding: 0 6.25%;
    margin-bottom: 120px;
  `}

  ${mq.from.L`
    padding: 0 12.5%;
  `}
`

const HappyImage = styled.img`
  margin: 0 0 40px;
  max-width: 320px;

  ${mq.from.M`
    height: 405px;
    max-width: 460px;
    margin: 0 64px 0 0;
  `}
`

const TagsImage = styled.img`
  margin: 0;
  height: 512px;
  min-width: 3800px;
  ${mq.from.M`
    margin: 0 240px 0 0;
  `}
`

const PatternImage = styled.img`
  margin: 0;
  display: none;
  ${mq.from.M`
    display: block;
    height: 100%;
    padding-right: 120px;
  `}
`

const ScrollHint = styled.div`
  @keyframes fadeDown {
    0% {
      opacity: 0;
      transform: translateY(-5vh);
    }
    40% {
      opacity: 1;
    }
    80% {
      opacity: 0;
      transform: translateY(5vh);
    }
    100% {
      opacity: 0;
      transform: translateY(5vh);
    }
  }

  width: 5vh;
  height: 5vh;
  margin: 0 auto;
  animation: fadeDown 2.4s ease-in-out infinite;
`

export default Header
