import React from "react"

// Types
import { IIconProps as IProps } from "./Icon"

const Arrow = ({ width, height, color }: IProps) => (
  <svg
    style={{
      width: `${String(width)}px`,
      height: `${String(height)}px`,
      transform: `rotate(90deg)`,
    }}
    viewBox="0 0 24 25"
    fill={color}
  >
    <path d="M13.025 1l-2.847 2.828 6.176 6.176H0v3.992h16.354l-6.176 6.176L13.025 23 24 12z" />
  </svg>
)

export default Arrow
