/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"

// Styling
import CSSReset from "../styles/reset"
import GlobalStyle from "../styles/"

// Components
import Header from "./Header"

const Layout: React.FC = ({ children }) => (
  <>
    <CSSReset />
    <GlobalStyle />
    <Header />
    <main>{children}</main>
  </>
)

export default Layout
